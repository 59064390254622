import './sentry.js';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import { store } from './store/store.js';
import apiEndPoints from './apiEndPoints.js';
import asyncComputed from './asyncComputed.js';
import methods from './helpers/mixins.js';
import VueSignaturePad from 'vue-signature-pad';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import PortalVue from 'portal-vue';
import { startAnalytics } from './analytics.js';
import ObserveSizeDirective from './ObserveSizeDirective.js';
import { I18nPlugin } from './i18n.js';
import './moment.js';
import { installTrackInputMixins } from './trackInput.js';
import './title.js';

startAnalytics();

if (new URL(location.href).searchParams.has('TEST_SENTRY')) {
    throw new Error('Testing Sentry');
}

Vue.config.productionTip = false;

installTrackInputMixins();
Vue.mixin(asyncComputed);
Vue.mixin({ methods: apiEndPoints });
Vue.mixin(methods);
Vue.use(VueSignaturePad);
Vue.use(VueTelInputVuetify, { vuetify });
Vue.use(PortalVue);
Vue.use(ObserveSizeDirective);
Vue.use(I18nPlugin);

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App),
}).$mount('#app');

if (
    !/^localhost$/.test(location.hostname) &&
    'serviceWorker' in window.navigator
) {
    window.navigator.serviceWorker.register('/service-worker.js');
}
